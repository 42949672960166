import ApiService from "@/core/services/api.service";
import {API_URL} from '@/common/config.js'

const apiService = ApiService;


export default class ResourceService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/resource`;

    }

    paginate(data = null, index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null && !queryString)
            url = url + '?' + "page=" + (index);
        else
            url = url + '&' + "page=" + (index);
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }


    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    resourcesByCategory(resourceId) {
        let url = `${this.#api}/category/${resourceId}`
        return apiService.get(url);
    }

    resourcesByKey(key) {
        let url = `${this.#api}`
        if (key)
            url = `${url}/search/${key}`
        return apiService.get(url);
    }

    resourceByProgramAndSemester(key) {
        let url = `${this.#api}/by/program-and-semester`
        return apiService.get(url);
    }

}