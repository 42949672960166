<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr class="text-center">
          <th class="px-3" style="max-width: 3000px !important; white-space: pre-wrap;">Wishlist</th>
          <th class="px-3" style="max-width: 280px !important; white-space: pre-wrap;">Name</th>
          <th class="px-3" style="max-width: 300px !important; white-space: pre-wrap;">Info</th>
          <th class="px-3">Stock</th>
          <th class="px-3">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="books.length > 0" v-for="item in books" :key="item.id">
          <td class="px-3" style="max-width: 3000px !important; white-space: pre-wrap;">
            <span v-if="!item.is_wishlisted" @click="addToWishList(item.id)"><i class="fas fa-heart"></i></span>
            <span v-else @click="removeFromWishList(item.wishlist_id)"><i class="fas fa-heart text-danger"></i></span>
          </td>
          <td class="px-3" style="max-width: 280px !important; white-space: pre-wrap;">
            <span class="font-weight-bolder  mb-1">
              {{ item.title }}
            </span>
            <div class="text-secondary body-1">
             <span class="font-weight-black "> Year:</span> {{ item.year_of_publication }}
            </div>
            <div class="text-secondary body-1">
              <span class="font-weight-black ">Edition:</span> {{ item.edition }}
            </div>
          </td>
          <td class="px-3" style="max-width: 300px !important; white-space: pre-wrap;">
            <div class="text-secondary ">
              <span class="font-weight-black "> Author:</span> {{ item.author }}
            </div>
            <div class="text-secondary ">
              <span class="font-weight-black "> Publisher:</span> {{ item.publisher }}
            </div>
            <div class="text-secondary ">
              <span class="font-weight-black "> Publisher:</span> {{ item.publication }}
            </div>
          </td>
          <td class="px-3">
            <span class="badge text-uppercase" :class="item.in_stock?'badge-primary':'badge-warning'">
            {{`${item.in_stock ? "available - " : "out of stock"}` }}
            {{`${item.in_stock ? item.total_available:''}`}}</span>
          </td>
          <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                          <span class="badge badge-success text-lg`"
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                          >{{ item.is_active ? 'Active' : 'Inactive' }}</span>
          </td>
        </tr>
      </tbody>
      <tbody>
      </tbody>
    </table>
    <div class="col-12 mt-2">
      <v-data-table
          class="table-responsive"
          col="12"
          :headers="book_headers"
          :items="books"
          :items-per-page="perPage"
          dense
          hide-default-footer
          :hide-default-header="disable_header"
      >
        <template v-slot:item.wishlist="{ item }">
          <span v-if="!item.is_wishlisted" @click="addToWishList(item.id)"><i class="fas fa-heart"></i></span>
          <span v-else @click="removeFromWishList(item.wishlist_id)"><i class="fas fa-heart text-danger"></i></span>
        </template>
        <template v-slot:item.total_issued_count="{ item }">
                    <span :class="item.total_issued_count>0?'text-success font-weight-bold':'text-muted'">
                      {{ item.total_issued_count }}
                    </span>
        </template>

        <template v-slot:item.title="{ item }" >
          <a href="#" @click="openBookDetail(item)" style="max-width: 150px !important; white-space: pre-wrap;">
            {{ item.title }}
          </a>
          <i :class="item.is_active?'dot-active':'dot-warning'"
             class="fas fa-circle"></i> <br/>
          <b>Year:</b> {{ item.year_of_publication }} <br/>
          <b>Edition:</b> {{ item.edition }} <br/>
        </template>
        <template v-slot:item.author="{ item }" >
         <span style="max-width: 200px !important; white-space: pre-wrap;">
           <b>Author</b>: {{ item.author }} <br/>
          <b>Publisher:</b> {{ item.publisher }} <br/>
          <b>Publication:</b> {{ item.publication }} <br/>
         </span>
        </template>
        <template v-slot:item.in_stock="{ item }">
          <span class="badge text-uppercase"
                :class="item.in_stock?'badge-primary':'badge-warning'">
            {{
              `${item.in_stock ? "available - " : "out of stock"}`
            }}
          {{      `${item.in_stock ? item.total_available:''}`}}</span>
        </template>


        <!--            <template v-slot:expanded-item="{ headers, item }">-->
        <!--              <v-row>-->
        <!--                <v-col cols="12">-->
        <!--                  <table class="table table-striped">-->
        <!--                    <tr>-->
        <!--                      <th>Author</th>-->
        <!--                      <td>{{ item.author }}</td>-->
        <!--                      <th>Publisher</th>-->
        <!--                      <td>{{ item.publisher }}</td>-->
        <!--                    </tr>-->
        <!--                    <tr>-->
        <!--                      <th>Publication</th>-->
        <!--                      <td>{{ item.publication }}</td>-->
        <!--                      <th>Edition</th>-->
        <!--                      <td>{{ item.edition }}</td>-->
        <!--                    </tr>-->
        <!--                    <tr>-->
        <!--                      <th>Year</th>-->
        <!--                      <td>{{ item.year_of_publication }}</td>-->
        <!--                    </tr>-->
        <!--                  </table>-->
        <!--                </v-col>-->
        <!--              </v-row>-->

        <!--            </template>-->
      </v-data-table>

    </div>
  </div>
</template>

<script>
export default {
  name: "BookList",
  props: ['books', 'disable_header'],
  data() {
    return {
      perPage: 50,
      book_headers: [
        {text: 'Wishlist', value: 'wishlist', sortable: false},
        {text: 'Name', value: 'title', sortable: true},
        {text: 'Info', value: 'author', sortable: true},
        // {text: 'Price', value: 'price', sortable: true},
        // {text: 'No of Pages', value: 'no_of_pages', sortable: true},
        {text: 'Stock', value: 'in_stock', sortable: true},
      ]
    }
  },
  methods: {
    addToWishList(id) {
      this.$emit('add_to_wishlist', id)
    }, removeFromWishList(id) {
      this.$emit('remove_from_wishlist', id)
    }
  }
}
</script>

<style scoped>

</style>