<template>
  <v-app>
    <v-row>
      <v-col cols="12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Book</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Book
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col class="col-md-3">
                  <v-text-field
                      v-model="search.name"
                      @keydown.enter="getBooks"
                      dense
                      outlined clearable
                      type="text"
                      label="Filter by book name, ISBN"
                      placeholder="Search by unique no., name, email or phone"
                  ></v-text-field>
                </v-col>
                <v-col class="col-md-2">
                  <v-select v-model="search.grade" :items="grade_history" class="ml-2" item-value="grade_id"
                            item-text="grade_title" outlined dense  @keydown.enter="getBooks"
                            placeholder="Student Grades"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                      @click="getBooks()"
                      class="btn btn-primary btn-search "
                      :loading="isLoading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-center">

                  <th class="px-3" style="max-width: 280px !important; white-space: pre-wrap;">Name</th>
                  <th class="px-3" style="max-width: 300px !important; white-space: pre-wrap;">Info</th>
                  <th class="px-3">Stock</th>
                  <th class="px-3">Action</th>

                </tr>
                </thead>
                <tbody>
                <tr v-show="books.length > 0" v-for="item in books" :key="item.id">

                  <td class="px-3" style="max-width: 280px !important; white-space: pre-wrap;">
                     <span class="font-weight-bolder  mb-1">
                      {{ item.title }}
                      </span>
                    <div class="text-secondary body-1">
                      <span class="font-weight-black "> Year:</span> {{ item.year_of_publication }}
                    </div>
                    <div class="text-secondary body-1 ml-1">
                      <span class="font-weight-black ">Edition:</span> {{ item.edition }}
                    </div>
                  </td>
                  <td class="wrap-column">
                    <div class="text-secondary">
                      <b>Author:</b> {{ item.author ? item.author : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <b>Publisher:</b> {{ item.publisher ? item.publisher : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <b>Publication:</b> {{ item.publication ? item.publication : 'N/A'  }}
                    </div>
                    <div class="text-secondary">
                      <strong>ISBN: </strong> {{ item.isbn_no ? item.isbn_no : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      <strong>Classification no.</strong>
                      {{ item.dewey_decimal_class_title ? item.dewey_decimal_class_title : 'N/A' }}
                    </div>
                  </td>
                  <td class="px-3">
                    <span class="badge text-uppercase" :class="item.in_stock?'badge-primary':'badge-warning'">
                          {{`${item.in_stock ? "available - " : "out of stock"}` }}
                          {{`${item.in_stock ? item.total_available:''}`}}</span>
                  </td>
                  <td>
                    <v-btn @click="addToWishList(item.id)" icon color="grey" v-if="!item.is_wishlisted">
                      <v-icon>mdi-heart-outline </v-icon>
                    </v-btn>
                    <v-btn @click="deleteWishlist(item.wishlist_id)" icon color="red" v-else>
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
                <tbody>
                </tbody>
              </table>
              <b-pagination
                  v-if="books.length > 0"
                  class="pull-right mt-7"
                  @input="getBooks"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="isLoading"
              ></b-pagination>
            </div>
<!--            <book-list  :books="books"
                       @add_to_wishlist="addToWishList"
                       @remove_from_wishlist="deleteWishlist"
                       :disable_header="false"
            ></book-list>-->
          </div>
        </div>

        <v-tabs-items v-model="tab">
                  <v-tab-item key="program" class="mb-3">
                    <v-row class="mt-5" v-if="book_by_programs && book_by_programs.length>0">
                      <v-col cols="12" v-for="(result, index) of book_by_programs" :key="index">
                        <span
                            class="font-size-sm font-weight-bold">{{
                            `${result.program ? result.program : ''} - ${result.grade ? result.grade : ''}`
                          }}</span>
                        <book-list v-if="result.books && result.books.length>0"
                                   :books="result.books"
                                   :disable_header="true"
                                   @add_to_wishlist="addToWishList"
                                   @remove_from_wishlist="deleteWishlist"
                        ></book-list>
                        <div class="row text-center" v-else>
                          <v-col>
                            <v-divider></v-divider>
                            <span class="font-size-sm">No book found</span>
                          </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="all_book">
                    <v-row class="mt-5">
                      <v-col cols="8">
                        <v-text-field
                            v-model="search.name"
                            @keydown.enter="getBooks"
                            dense
                            outlined
                            lass="form-control border-0 font-weight-bold pl-2"
                            type="text"
                            label="Filter by Book Name, ISBN"
                            placeholder="Search By Unique No, Name, Email or Phone"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-btn class="btn btn-primary text-white" @click="getBooks()">Search</v-btn>
                      </v-col>
                    </v-row>
                    <book-list :books="books"
                               @add_to_wishlist="addToWishList"
                               @remove_from_wishlist="deleteWishlist"
                               :disable_header="false"
                    ></book-list>
                    <div class="row" v-if="total>perPage">
                      <div class="col-12">
                        <b-pagination
                            @input="getBooks"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
                        ></b-pagination>
                      </div>
                    </div>
                  </v-tab-item>

                </v-tabs-items>
      </v-col>
    </v-row>
  </v-app>

</template>

<script>

import ResourceService from "@/core/services/library/resource/ResourceService";
import BookService from "@/core/services/library/book/BookService";
import WishlistService from "@/core/services/library/wishlist/WishlistService";
import SettingService from "@/core/services/library/setting/SettingService";
import BookList from "@/view/component/library/BookList";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";
import UserService from "@/core/services/user/UserService";


const resourceService = new ResourceService();
const bookService = new BookService();
const wishlistService = new WishlistService();
const librarySetting = new SettingService();
const studentSettingService = new StudentSettingService();
const userService = new UserService();

export default {
  name: "index",
  components: {
    BookList
  },
  data() {
    return {
      student_history: [],
      grade_history: [],
      categories: [],
      resources: [],
      books: [],
      book_by_programs: [],
      tabs: null,
      key: null,
      setting: {},
      page: null,
      total: null,
      perPage: null,
      tab: null,
      isLoading: true,
      loading: true,
      wishlist_count: 0,
      search: {
        status: 'active',
        type: 'student'
      }
    }
  },
  mounted() {
    this.studentHistory()
    this.getLibrarySetting()
    // this.getUserWishlist()
    this.getBooks()
  }, computed: {
    user() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    getLibrarySetting() {
      librarySetting.first().then(resposne => {
        this.setting = resposne.data
      })
    },
    studentHistory() {
      userService.userHistory().then(resposne => {
        this.student_history = resposne.data
        if (resposne.data.status == "OK") {
          this.grade_history = this.student_history.history.grade_history
        }
      })
    },
    getUserWishlist() {
      wishlistService.userWishlist(this.user.id).then(response => {
        this.wishlist_count = response.data.wishlist.length;
      })
    },
    getResourceByKey() {
      if (this.key.length > 2) {
        this.isLoading = true
        bookService.resourcesByKey(this.key).then(response => {
          this.books = response.data.data
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.resources = [];
          this.isLoading = false
        })
      }
    },
    bookByProgramAndSemester() {
      bookService.bookByProgramAndSemester().then(response => {
        this.book_by_programs = response.data
      })
    },
    getBooks() {
      this.bookByProgramAndSemester();
      this.isLoading = true
      bookService
          .paginate(this.search, this.page)
          .then(response => {
              this.books = response.data.data
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
              this.resources = [];
              this.isLoading = false
        }).finally(() => {
            this.isLoading = false
         })
    },
    bookDetail(id) {
      this.$router.push({name: 'book-detail', params: {id: id}})
    },
    addToWishList(bookId) {
      if (this.wishlist_count >= this.setting.max_wishlist_allowed) {
        this.$snotify.error(`No more than ${this.setting.max_wishlist_allowed} books allowed in wishlist`);
      } else {
        this.$confirm({
          message: `Are you sure you want to add this book in your wishlist?`,
          button: {
            no: "No",
            yes: "Yes"
          },
          callback: confirm => {
            if (confirm) {
              let data = {book_id: bookId, user_id: this.user.id}
              wishlistService.store(data).then(response => {
                this.$snotify.success("Successfully added to wishlist");
                this.getBooks();
                this.getUserWishlist()
              })
            }
          }
        })
      }
    }, myWishlist() {
      this.$router.push({name: 'my-wishlist'});
    }, myIssue() {
      this.$router.push({name: 'my-issue'});
    },
    deleteWishlist(id) {
      this.$confirm({
        message: `Are you sure you want to remove this book from your wishlist?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            wishlistService.delete(id).then(response => {
              this.$snotify.success("Wishlist deleted successfully")
              this.getBooks();
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
